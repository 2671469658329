import {
  LOGIN_RESPONSE,
  VERIFY_PIN_RESPONSE,
  GET_IP_RESPONSE,
} from './login.types'

/**
 * @fileOverview Manages the response of action
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 * @example switch (action.type) {
    case TYPE_NAME: {
      return {
        ...state,
        ...{INITIAL_STATE: action.payload },
      }
    }
    }
 */

const INITIAL_STATE = {
  login: false,
  loginResponse: {},
  pinResponse: {},
  ipResponse: {},
}

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_RESPONSE: {
      return { ...state, ...{ login: true, loginResponse: action.payload } }
    }
    case VERIFY_PIN_RESPONSE: {
      return { ...state, ...{ pinResponse: action.payload } }
    }
    case GET_IP_RESPONSE: {
      return { ...state, ...{ ipResponse: action.payload } }
    }
    default:
      return state
  }
}
export default loginReducer
