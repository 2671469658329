import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
// import { ajax as utlAjax } from 'Utils'
import { LOGIN, REQUEST_CANCEL, VERIFY_PIN, GET_IP } from './login.types'
import { loginResponse, checkPinResponse, getIpResponse } from './login.actions'
import environment from 'environments/environment'
import { Apis } from 'Redux/APIs'
const baseUrl = environment.baseUrl

const loginEpic = {}

loginEpic.login = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    switchMap((action) =>
      ajax({
        headers: {
          'Content-Type': 'application/json',
        },
        url: `${baseUrl}/${Apis.Login_login}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => loginResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

loginEpic.verifyPin = (action$) =>
  action$.pipe(
    ofType(VERIFY_PIN),
    switchMap((action) =>
      ajax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/${Apis.Login_verifyPin}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => checkPinResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

loginEpic.getIpApi = (action$) =>
  action$.pipe(
    ofType(GET_IP),
    switchMap(() =>
      ajax({
        url: `https://geolocation-db.com/json/`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getIpResponse(response.response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

export default loginEpic
