import React, { useEffect, useState } from 'react'
import { useForm } from 'Hooks/validator'
import validator from 'validator'
import PropTypes from 'prop-types'
import { ErrorHandler } from 'Components/ErrorHandling'
import { connect } from 'react-redux'
import { clickLogin, loginResponse } from 'Redux/Login/login.actions'

import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles'

import { Typography, Grid } from '@material-ui/core'
import Input from '../../BaseComponents/Input'
import Datepicker from '../../BaseComponents/Date'
import LoginBtn from './LoginBtn'
import trilliumHealthLogo from '../../assets/images/trillium-health-logo.png'

const useStyles = makeStyles(() => ({
  logo: {
    width: '180px',
  },
  Login: {
    textAlign: 'center',
    height: '100vh',
  },

  /* payment Login header */
  Login__header: {
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #e0e0e0',
  },
  Login__header__bar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '90%',
    padding: '1rem 0rem',
    '& ul': {
      display: 'inline-flex',
      gap: '2rem',
      margin: 0,
      fontSize: '1.125rem',
      fontWeight: 500,
      color: '#5f6368',
      listStyleType: 'none',
      '& li a': {
        color: '#5f6368',
        textDecoration: 'none',
        '&:hover': {
          color: '#2b2b2c',
        },
        '@media(max-width: 768px)': {
          fontSize: '0.9rem',
        },
      },
    },
  },
  /* ******* */

  Login__main: {
    margin: '0 auto',
    maxWidth: '1440px',
    '@media(max-width: 1440px)': {
      maxWidth: '1240px',
    },
    '@media(max-width: 1024px)': {
      maxWidth: '800px',
    },
  },
  Login__section: {
    margin: '60px 180px',
    '@media(max-width: 425px)': {
      margin: '50px 22px',
    },
    '@media(min-width: 1025px)': {
      margin: '60px 360px',
    },
  },
  Login__grid: {
    // display: 'grid',
    gridTemplateColumns: '1.2fr 1fr',
    placeItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '3rem',
  },
  Login__grid__col: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  Login__grid__colForm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  Login__grid__heading: {
    letterSpacing: '-0.5px',
    fontWeight: 500,
    marginBottom: '1rem',
    fontSize: '36px',
    lineHeight: '40px',
  },
  Login__grid__subheading: {
    letterSpacing: 'initial',
    color: '#6a6e74',
    fontWeight: 500,
    margin: '0.3rem 0.3rem 1rem',
    fontSize: '18px',
    lineHeight: '24px',
    '@media(min-width: 1025px)': {
      margin: '0.5rem 2.2rem 1rem',
    },
  },
  Login__form: {
    display: 'flex',
    flexDirection: 'column',
  },
  pinInput: {
    margin: '0 0 1rem',
  },
  Login__copyright: {
    padding: '1rem 0',
  },
}))

// custom theme for input InputField and KeyboardDatePicker
const InputFieldTheme = createTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: 'rgba(0, 0, 0, 0.54)',
          fontWeight: 'normal',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#088B8C',
    },
  },
})

const LoginPageTheme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
})

function Login(props) {
  const formatDate = (date) => {
    if (date === null) {
      return null
    }
    const d = new Date(date)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = d.getFullYear()
    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + day
    }
    if (isNaN(year)) {
      return null
    } else {
      return [year, month, day].join('-')
    }
  }
  const [Ipaddress, setIpaddress] = useState(null)
  const [browser, setBrowser] = useState(null)
  const [version, setVersion] = useState(null)
  const [dob, Setdob] = useState(null)
  const [validDob, SetvalidDob] = useState(false)
  const [apiError, SetapiError] = useState(false)
  const [dobMessage, SetdobMessage] = useState(null)
  const [apiMessage, SetapiMessage] = useState(null)

  useEffect(() => {
    // Setdob('02/03/1997')
    fetch('https://api.ipify.org?format=json')
      .then((res) => res.json())
      .then(
        (result) => {
          setIpaddress(result.ip)
        },

        (error) => {},
      )
    //Finding Browser and version
    var navUserAgent = navigator.userAgent
    var browserName = navigator.appName
    var browserVersion = '' + parseFloat(navigator.appVersion)
    // var majorVersion = parseInt(navigator.appVersion, 10)
    var tempNameOffset, tempVersionOffset, tempVersion

    if ((tempVersionOffset = navUserAgent.indexOf('Opera')) !== -1) {
      browserName = 'Opera'
      browserVersion = navUserAgent.substring(tempVersionOffset + 6)
      if ((tempVersionOffset = navUserAgent.indexOf('Version')) !== -1)
        browserVersion = navUserAgent.substring(tempVersionOffset + 8)
    } else if ((tempVersionOffset = navUserAgent.indexOf('MSIE')) !== -1) {
      browserName = 'Microsoft Internet Explorer'
      browserVersion = navUserAgent.substring(tempVersionOffset + 5)
    } else if ((tempVersionOffset = navUserAgent.indexOf('Chrome')) !== -1) {
      browserName = 'Chrome'
      browserVersion = navUserAgent.substring(tempVersionOffset + 7)
    } else if ((tempVersionOffset = navUserAgent.indexOf('Safari')) !== -1) {
      browserName = 'Safari'
      browserVersion = navUserAgent.substring(tempVersionOffset + 7)
      if ((tempVersionOffset = navUserAgent.indexOf('Version')) !== -1)
        browserVersion = navUserAgent.substring(tempVersionOffset + 8)
    } else if ((tempVersionOffset = navUserAgent.indexOf('Firefox')) !== -1) {
      browserName = 'Firefox'
      browserVersion = navUserAgent.substring(tempVersionOffset + 8)
    } else if (
      (tempNameOffset = navUserAgent.lastIndexOf(' ') + 1) <
      (tempVersionOffset = navUserAgent.lastIndexOf('/'))
    ) {
      browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset)
      browserVersion = navUserAgent.substring(tempVersionOffset + 1)
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName
      }
    }

    // trim version
    if ((tempVersion = browserVersion.indexOf(';')) !== -1)
      browserVersion = browserVersion.substring(0, tempVersion)
    if ((tempVersion = browserVersion.indexOf(' ')) !== -1)
      browserVersion = browserVersion.substring(0, tempVersion)

    // alert('BrowserName = ' + browserName + '\n' + 'Version = ' + browserVersion)
    setBrowser(browserName)
    setVersion(browserVersion)
  }, [])
  
  const classes = useStyles()
  const defaultValues = {
    pin: '',
  }
  const customErrorAttribute = {
    haserror: true,
  }
  const { values, isValid, useInput, setValues } = useForm(
    defaultValues,
    customErrorAttribute,
  )
  const loginFunction = (e) => {
    e.preventDefault()
    if (isValid && dob && validDob === false) {
      props.submitLogin({
        patientDob: formatDate(dob),
        ipAddress: Ipaddress,
        patientPinNo: values.pin,
        browserVersion: `${browser + version}`,
      })
    }
  }

  useEffect(() => {
    localStorage.removeItem('access_token')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      props.LoginData &&
      props.LoginData.loginResponse &&
      props.LoginData.loginResponse.status &&
      props.LoginData.loginResponse.response
    ) {
      const { response } = props.LoginData.loginResponse
      if (props.LoginData.loginResponse.response.error) {
        const { message } = props.LoginData.loginResponse.response.error
        if (response && response.responseCode) {
          const Errobject = ErrorHandler(response.responseCode, message)
          if (Errobject) {
            // toast.error(Errobject.message)
            SetapiMessage(Errobject.message)
            SetapiError(true)
            SetvalidDob(true)
          }
        }
      } else {
        const { response } = props.LoginData.loginResponse
        if (
          props.LoginData.loginResponse.response.data &&
          response.responseCode === 0
        ) {
          localStorage.setItem(
            'access_token',
            props.LoginData.loginResponse.response.data.accessToken,
          )
          localStorage.setItem(
            'patientId',
            JSON.stringify(
              props.LoginData.loginResponse.response.data.patientId,
            ),
          )
          localStorage.setItem('minute', 0)
          window.location.href = '/payment'
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.LoginData.loginResponse])

  const handledate = (date) => {
    Setdob(date)
    SetvalidDob(false)
    SetdobMessage(null)
  }
  const validateDob = () => {
    if (dob) {
      const mindate = new Date('01/01/1871')
      const date = dob
      if (
        validator.isDate(formatDate(date)) &&
        date < new Date() &&
        date > mindate
      ) {
        SetvalidDob(false)
        SetdobMessage(null)
      } else {
        SetvalidDob(true)
        SetdobMessage('Enter a valid date')
      }
    } else {
      SetvalidDob(true)
      SetdobMessage('Enter a valid date')
    }
  }

  useEffect(() => {
    let val = validator.trim(values.pin)
    setValues({
      ...values,
      ...{
        pin: val,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.pin])

  return (
    <ThemeProvider theme={LoginPageTheme}>
      <div className={classes.Login}>
        <header className={classes.Login__header}>
          <div className={classes.Login__header__bar}>
            <a href="https://trillium.health/">
              <img
                className={classes.logo}
                src={trilliumHealthLogo}
                alt="trillium-health-logo"
              />
            </a>
          </div>
        </header>

        <main className={classes.Login__main}>
          <section className={classes.Login__section}>
            <div className={classes.Login__grid}>
              <div className={classes.Login__grid__col}>
                <h1 className={classes.Login__grid__heading}>
                  Welcome to Trillium Appointment Portal
                </h1>
                <h2 className={classes.Login__grid__subheading}>
                  Effortlessly book appointments with your physician online.
                </h2>
              </div>
              <div className={classes.Login__grid__colForm}>
                <form
                  className={classes.Login__form}
                  autoComplete="off"
                  onSubmit={loginFunction}
                >
                  <ThemeProvider theme={InputFieldTheme}>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                      <Input
                        haserror={apiError}
                        helperText={apiMessage}
                        inputProps={{ maxLength: 12 }}
                        placeholder="12-digit pin number"
                        id="pin-number"
                        className={classes.pinInput}
                        type="text"
                        variant="outlined"
                        required
                        {...useInput('pin', {
                          matches: {
                            value: /^[0-9A-Za-z]+$/,
                            message:
                              'Please enter the valid PIN with numbers and letters',
                          },
                        })}
                      />

                      <Datepicker
                        required
                        value={dob}
                        onChange={handledate}
                        helperText={dobMessage}
                        error={validDob}
                        onBlur={validateDob}
                        maxDate={new Date()}
                      />
                      <LoginBtn />
                    </Grid>
                  </ThemeProvider>
                </form>
              </div>
            </div>
          </section>
        </main>
        <div className={classes.Login__copyright}>
          <Typography
            variant="caption"
            align="center"
            color="textSecondary"
            display="block"
          >
            Copyright &copy; {new Date().getFullYear()}-
            {new Date().getFullYear() + 1} Tensaw.health
          </Typography>
        </div>
      </div>
    </ThemeProvider>
  )
}

const mapStateToProps = (state) => ({
  LoginData: state.login,
})

const mapDispatchToProps = (dispatch) => ({
  submitLogin: (values) => dispatch(clickLogin(values)),
  loginResponse: (values) => dispatch(loginResponse(values)),
})
Login.propTypes = {
  submitLogin: PropTypes.func,
  loginResponse: PropTypes.func,
  logged: PropTypes.bool,
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
