import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { clickLogin, checkPin, getIp } from 'Redux/Login/login.actions'
import { Button, Grid, Typography } from '@material-ui/core'
import Datepicker from '../../BaseComponents/Date'
import { makeStyles, createTheme } from '@material-ui/core/styles'
import trilliumHealthLogo from '../../assets/images/trillium-health-logo.png'
import { ThemeProvider } from '@material-ui/styles'
import Loader from 'BaseComponents/Loader'
// import axios from 'axios'
import './style.css'

const authTheme = createTheme({
  palette: {
    primary: {
      main: '#088B8C',
    },
    secondary: {
      main: '#5F6064',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
})

const useStyles = makeStyles((theme) => ({
  root: {
    // '@media(min-width: 960px)': {
    //   display: 'flex',
    //   justifyContent: 'center',
    //   height: '100vh',
    //   width: '100%',
    // },
  },
  header: {
    minHeight: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #e0e0e0',
  },
  header__bar: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100%',
    width: '90%',
  },
  logo: {
    width: '180px',
  },
  containerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    '@media(max-width: 200px)': {
      display: 'block',
      height: 'fit-content',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '2rem',
    '@media(min-width: 760px)': {
      height: '75%',
      width: '400px',
    },
  },
  name: {
    display: 'flex',
    justifyContent: 'start',
    textAlign: 'start',
    margin: '4rem 0 1rem',
    color: '#14A6A6',
    fontWeight: '500',
    fontSize: '36px',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  subHeading: {
    margin: '0 0 3rem',
    color: '#4F566B',
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '24px',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  clinicName: {
    fontWeight: '600',
  },
  dob: {
    margin: '0 0 1rem',
  },
  confirmBtn: {
    margin: '1.5rem 0 1rem',
    width: '100%',
  },
  copyright: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
}))

function Authenticate(props) {
  const classes = useStyles()
  let pin = window.location.href.split('/').pop()
  const [dob, setDob] = useState(null)
  const [details, setDetails] = useState(null)
  const [pinCode, setPinCode] = useState('')
  const [invalidDob, setInvalidDob] = useState(false)
  const [dobMessage, setDobMessage] = useState('')
  const [Ipaddress, setIpaddress] = useState(null)
  const [browser, setBrowser] = useState(null)
  const [version, setVersion] = useState(null)
  const [loading, setloading] = useState(true)

  const formatDate = (date) => {
    if (date === null) {
      return null
    }
    const d = new Date(date)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = d.getFullYear()
    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + day
    }
    if (isNaN(year)) {
      return null
    } else {
      return [year, month, day].join('-')
    }
  }

  function hideWord(w) {
    if (w.length < 3) {
      return w
    } else {
      return w.substring(0, 2) + '*'.repeat(w.length - 2)
    }
  }

  function hideWords(s) {
    return s
      .split(' ')
      .map((word) => hideWord(word))
      .join(' ')
  }

  function handledate(e) {
    setDob(e)
  }

  useEffect(() => {
    localStorage.removeItem('providerId')
    localStorage.removeItem('locationId')
    localStorage.removeItem('selectedTime')
    localStorage.removeItem('timeId')
    localStorage.removeItem('bookingId')
    localStorage.removeItem('clinicName')
    localStorage.removeItem('access_token')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (pin) {
      props.checkPin(pin.toString())
    } else {
      window.location.href = '/login'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      props.LoginData &&
      props.LoginData.pinResponse &&
      props.LoginData.pinResponse.response
    ) {
      let data = props.LoginData.pinResponse.response.data
      if (data) {
        setDetails(data)
        setPinCode(data.patientPinNo)
        localStorage.setItem('clinicName', data.clinicName)
        localStorage.setItem('getConsent', data.consent)
        if (data.previousAppointmentId) {
          localStorage.setItem('providerId', data.previousAppointmentProviderId)
          localStorage.setItem('locationId', data.previousAppointmentLocationId)
          localStorage.setItem('appointmentId', data.previousAppointmentId)
        } else {
          localStorage.setItem('providerId', 'a')
          localStorage.setItem('locationId', 'a')
          localStorage.setItem('appointmentId', null)
        }
        setloading(false)
      } else {
        window.location.href = '/login'
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.LoginData.pinResponse])

  // const getData = async () => {
  //   const res = await axios.get('https://geolocation-db.com/json/')
  //   setIpaddress(res.data.IPv4)
  // }

  useEffect(() => {
    // getData()
    props.getIp()
    //Finding Browser and version
    var navUserAgent = navigator.userAgent
    var browserName = navigator.appName
    var browserVersion = '' + parseFloat(navigator.appVersion)
    // var majorVersion = parseInt(navigator.appVersion, 10)
    var tempNameOffset, tempVersionOffset, tempVersion

    if ((tempVersionOffset = navUserAgent.indexOf('Opera')) !== -1) {
      browserName = 'Opera'
      browserVersion = navUserAgent.substring(tempVersionOffset + 6)
      if ((tempVersionOffset = navUserAgent.indexOf('Version')) !== -1)
        browserVersion = navUserAgent.substring(tempVersionOffset + 8)
    } else if ((tempVersionOffset = navUserAgent.indexOf('MSIE')) !== -1) {
      browserName = 'Microsoft Internet Explorer'
      browserVersion = navUserAgent.substring(tempVersionOffset + 5)
    } else if ((tempVersionOffset = navUserAgent.indexOf('Chrome')) !== -1) {
      browserName = 'Chrome'
      browserVersion = navUserAgent.substring(tempVersionOffset + 7)
    } else if ((tempVersionOffset = navUserAgent.indexOf('Safari')) !== -1) {
      browserName = 'Safari'
      browserVersion = navUserAgent.substring(tempVersionOffset + 7)
      if ((tempVersionOffset = navUserAgent.indexOf('Version')) !== -1)
        browserVersion = navUserAgent.substring(tempVersionOffset + 8)
    } else if ((tempVersionOffset = navUserAgent.indexOf('Firefox')) !== -1) {
      browserName = 'Firefox'
      browserVersion = navUserAgent.substring(tempVersionOffset + 8)
    } else if (
      (tempNameOffset = navUserAgent.lastIndexOf(' ') + 1) <
      (tempVersionOffset = navUserAgent.lastIndexOf('/'))
    ) {
      browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset)
      browserVersion = navUserAgent.substring(tempVersionOffset + 1)
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName
      }
    }

    // trim version
    if ((tempVersion = browserVersion.indexOf(';')) !== -1)
      browserVersion = browserVersion.substring(0, tempVersion)
    if ((tempVersion = browserVersion.indexOf(' ')) !== -1)
      browserVersion = browserVersion.substring(0, tempVersion)

    // alert('BrowserName = ' + browserName + '\n' + 'Version = ' + browserVersion)
    setBrowser(browserName)
    setVersion(browserVersion)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.LoginData && props.LoginData.ipResponse) {
      let ip = props.LoginData.ipResponse.IPv4
      setIpaddress(ip)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.LoginData.ipResponse])

  const loginFunction = (e) => {
    e.preventDefault()
    props.submitLogin({
      userName: pinCode,
      password: formatDate(dob),
      ipAddress: Ipaddress,
      browserVersion: `${browser + version}`,
      device: isMobile ? '2' : '1',
    })
    setloading(true)
  }

  useEffect(() => {
    if (
      props.LoginData &&
      props.LoginData.loginResponse &&
      props.LoginData.loginResponse.response
    ) {
      if (props.LoginData.loginResponse.response.responseType === 0) {
        const data = props.LoginData.loginResponse.response
        localStorage.setItem('access_token', data.accessToken)
        window.location.href = '/appointment'
      } else if (props.LoginData.loginResponse.response.responseType === 3) {
        const message = 'Invalid date of birth'
        setloading(false)
        setInvalidDob(true)
        setDobMessage(message)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.LoginData.loginResponse])

  let render = loading ? (
    <Loader />
  ) : (
    <ThemeProvider theme={authTheme}>
      <div className={classes.root}>
        <header className={classes.header}>
          <div className={classes.header__bar}>
            <a href="https://tensaw.health/">
              <img
                src={trilliumHealthLogo}
                alt="trillium-health-logo"
                className={classes.logo}
              />
            </a>
          </div>
        </header>
        <div className={classes.containerWrapper}>
          <Grid container className={classes.container}>
            <Grid xs={12} sm={12} item>
              <Typography className={classes.name}>
                {details
                  ? hideWords(
                      details.patientFirstName + ' ' + details.patientLastName,
                    )
                  : 'Unknown'}
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} item>
              <p className={classes.subHeading}>
                Confirm your date of birth to make your appointment with{' '}
                <span className={classes.clinicName}>
                  {details ? details.clinicName : 'clinic'}
                </span>
              </p>
            </Grid>

            <Grid xs={12} sm={12} item>
              <div className={classes.dob}>
                <Datepicker
                  required
                  value={dob}
                  onChange={handledate}
                  helperText={dobMessage}
                  error={invalidDob}
                  maxDate={new Date()}
                />
              </div>
            </Grid>
            <Grid xs={12} sm={12} item>
              <Button
                className={classes.confirmBtn}
                variant="contained"
                color="primary"
                size="large"
                onClick={loginFunction}
              >
                Confirm
              </Button>
            </Grid>
            {/* <Grid xs={12} sm={12} item>
              <input type="checkbox" id="remind" name="remind" />
              <label for="remind"> Remember this device for 30 days</label>
            </Grid> */}
          </Grid>
        </div>
        <div className={classes.copyright}>
          <Typography
            variant="caption"
            align="center"
            color="textSecondary"
            display="block"
          >
            Copyright &copy; {new Date().getFullYear()}-
            {new Date().getFullYear() + 1} trillium.health
          </Typography>
        </div>
      </div>
    </ThemeProvider>
  )

  return render
}

const mapStateToProps = (state) => ({
  LoginData: state.login,
})

const mapDispatchToProps = (dispatch) => ({
  submitLogin: (values) => dispatch(clickLogin(values)),
  checkPin: (values) => dispatch(checkPin(values)),
  getIp: (values) => dispatch(getIp(values)),
})
Authenticate.propTypes = {
  submitLogin: PropTypes.func,
  checkPin: PropTypes.func,
  getIp: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Authenticate)
