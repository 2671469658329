import {
  GET_LOCATIONS,
  GET_LOCATIONS_RESPONSE,
  GET_PROVIDERS,
  GET_PROVIDERS_RESPONSE,
  GET_TIME_SLOTS,
  GET_TIME_SLOTS_RESPONSE,
  GET_PATIENT_DETAILS,
  GET_PATIENT_DETAILS_RESPONSE,
  CONFIRM,
  CONFIRM_RESPONSE,
  GET_APPOINTMENT_DETAILS,
  GET_APPOINTMENT_DETAILS_RESPONSE,
} from './appointment.types'

export const getLocations = (data) => ({
  type: GET_LOCATIONS,
  payload: data,
})

export const getLocationsResponse = (data) => ({
  type: GET_LOCATIONS_RESPONSE,
  payload: data,
})

export const getProviders = (data) => ({
  type: GET_PROVIDERS,
  payload: data,
})

export const getProvidersResponse = (data) => ({
  type: GET_PROVIDERS_RESPONSE,
  payload: data,
})

export const getTimeSlots = (data) => ({
  type: GET_TIME_SLOTS,
  payload: data,
})

export const getTimeSlotsResponse = (data) => ({
  type: GET_TIME_SLOTS_RESPONSE,
  payload: data,
})

export const getPatientDetails = (data) => ({
  type: GET_PATIENT_DETAILS,
  payload: data,
})

export const getPatientDetailsResponse = (data) => ({
  type: GET_PATIENT_DETAILS_RESPONSE,
  payload: data,
})

export const confirm = (data) => ({
  type: CONFIRM,
  payload: data,
})

export const confirmResponse = (data) => ({
  type: CONFIRM_RESPONSE,
  payload: data,
})

export const getAppointmentDetails = (data) => ({
  type: GET_APPOINTMENT_DETAILS,
  payload: data,
})

export const getAppointmentDetailsResponse = (data) => ({
  type: GET_APPOINTMENT_DETAILS_RESPONSE,
  payload: data,
})
