import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useForm } from 'Hooks/validator'
import {
  getLocations,
  getProviders,
  getTimeSlots,
} from 'Redux/Appointment/appointment.actions'
import { Button, Grid, Typography } from '@material-ui/core'
import { makeStyles, createTheme } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { ThemeProvider } from '@material-ui/styles'
import Dropdown from 'BaseComponents/Dropdown'
import Datepicker from 'BaseComponents/AppointmentDate'
import EditIcon from '@material-ui/icons/Edit'
// import Loader from 'BaseComponents/Loader'
import './style.css'

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth)

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  // Return the width so we can use it in our components
  return { width }
}

const authTheme = createTheme({
  palette: {
    primary: {
      main: '#088B8C',
    },
    secondary: {
      main: '#5F6064',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
})

const useStyles = makeStyles((theme) => ({
  root: {
    '@media(min-width: 960px)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },

  containerWrapper: {
    display: 'block',
    height: 'fit-content',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '2rem',
    '@media(min-width: 960px)': {
      width: '650px',
    },
  },
  nameWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'flex-start',
    height: '100%',
    width: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  name: {
    fontSize: '22px',
    fontWeight: '600',
    color: '#1A1F36',
    margin: '0 0 1rem',
    width: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis !important',
  },
  subHeading: {
    margin: '0 0 2rem',
    // textAlign: 'justify',
    // textJustify: 'inter-word',
  },
  clinicName: {
    fontWeight: '600',
    // marginBottom: '30px',
  },
  dropdown: {
    backgroundColor: '#F2F2F2',
    color: '#2A2F45',
    fontWeight: '500',
    height: '36px',
    paddingLeft: '16px',
    // marginLeft: '8px',
    marginTop: '0 !important',
    borderRadius: '10px !important',
    '&:before': {
      borderColor: '#F2F2F2 !important',
    },
    '&:after': {
      borderColor: '#0D9797 !important',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: '#F2F2F2 !important',
    },
  },
  label: {
    justifyContent: 'space-between',
  },
  labelName: {
    fontWeight: '500',
  },
  dateField: {
    color: '#2A2F45',
    fontWeight: '500',
    width: '100%',
    marginTop: '0 !important',
    borderRadius: '10px !important',
    '&:before': {
      borderColor: '#F2F2F2 !important',
      display: 'none',
    },
    '&:after': {
      borderColor: '#0D9797 !important',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: '#F2F2F2 !important',
    },
    '& .MuiInput-underline-187:before': {
      borderBottom: '0 solid white !important',
    },
    '@media(min-width: 426px)': {
      backgroundColor: '#F2F2F2',
      height: '36px',
      paddingLeft: '16px',
      paddingTop: '3px',
    },
    // '& .MuiTextField-root': {
    //   '& .MuiInput-underline:before' : {
    //     display: 'none !important',
    //   }
    // },
    '@media(max-width: 425px)': {
      '& .MuiInputBase-root-189': {
        flexDirection: 'row-reverse',
      },
    },
  },
  Btncontainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem 2rem',
  },
  confirmBtn: {
    margin: '0.1rem 0 1rem',
    width: '100%',
  },
  copyright: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  editIcon: {
    color: '#8792A2',
    fontSize: '16px',
    marginTop: '3px',
  },
}))

const slots = [
  { name: 'All time slots', value: 4 },
  { name: 'Morning', value: 1 },
  { name: 'Afternoon', value: 2 },
  { name: 'Evening', value: 3 },
]

const month = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const days = ['', 'Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']

function Appointment(props) {
  const classes = useStyles()
  let clinic = localStorage.getItem('clinicName')
  let l = localStorage.getItem('locationId')
  let p = localStorage.getItem('providerId')
  const [locations, setLocations] = useState([])
  const [providers, setProviders] = useState([])
  // const [providersByLocation, setProvidersByLocation] = useState([])
  const [providerId, setProviderId] = useState('a')
  const [locationId, setLocationId] = useState('a')
  const [timeSlots, setTimeSlots] = useState([])
  const [timeSlotsMobile, setTimeSlotsMobile] = useState([])
  const [dates, setDates] = useState([])
  const [datesMobile, setDatesMobile] = useState([])
  const d = new Date()
  d.setDate(d.getDate() + 1)
  const min = d
  const m = new Date()
  m.setDate(m.getDate() + 11)
  const max = m
  const [calendarDate, setCalendarDate] = useState(min)
  const [disableLeftClick, setDisableLeftClick] = useState(true)
  const [disableRightClick, setDisableRightClick] = useState(false)
  const [appTime, setAppTime] = useState(null)
  const [expand, setExpand] = useState(false)
  // const [loading, setloading] = useState(true)
  const [condition, setCondition] = useState(false)

  const { width } = useViewport()
  const breakpoint = 750

  const formatDate = (date) => {
    if (date === null) {
      return null
    }
    const d = new Date(date)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = d.getFullYear()
    if (month.length < 2) {
      month = '0' + month
    }
    if (day.length < 2) {
      day = '0' + day
    }
    if (isNaN(year)) {
      return null
    } else {
      return [year, month, day].join('-')
    }
  }

  const TimestamptoTime = (timestamp) => {
    const time = new Date(timestamp).toLocaleTimeString('en-US')
    let hours = new Date(timestamp).getHours()
    let ampm = hours >= 12 ? 'PM' : 'AM'
    const split1 = time.split(':')
    // const split2 = split1[2].split('')
    const newTime = `${split1[0]}:${split1[1]} ${ampm}`
    return newTime
  }

  const defaultValues = {
    date: formatDate(calendarDate),
    providerId: localStorage.getItem('providerId'),
    locationId: localStorage.getItem('locationId'),
    sort: 4,
  }

  const { values, useInput, setValues } = useForm(defaultValues)

  useEffect(() => {
    props.getLocations(0)
    props.getProviders(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (l === 'a' || p === 'a') {
      setCondition(true)
    }
    setProviderId(p)
    setLocationId(l)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLocationChange = (e) => {
    setValues({
      ...values,
      ...{
        locationId: e.target.value,
      },
    })
    setLocationId(e.target.value)
    // localStorage.setItem('locationId', e.target.value)
  }

  const handleProviderChange = (e) => {
    setValues({
      ...values,
      ...{
        providerId: e.target.value,
      },
    })
    setProviderId(e.target.value)
    // localStorage.setItem('providerId', e.target.value)
  }

  useEffect(() => {
    if (
      props.appData &&
      props.appData.locationsResponse &&
      props.appData.locationsResponse.response
    ) {
      if (props.appData.locationsResponse.response.responseCode === 0) {
        let locationData = props.appData.locationsResponse.response.data
        if (locationData) {
          let data = locationData.map((item) => {
            return {
              name: item.locationName + ', ' + item.city,
              value: item.id,
            }
          })
          data.unshift({ name: 'All locations', value: 'a' })
          setLocations(data)
        } else {
          let data = [{ name: 'All locations', value: 'a' }]
          setLocations(data)
          setLocationId('a')
        }
      } else if (
        props.appData.locationsResponse.response.responseCode === 131
      ) {
        let data = [{ name: 'All locations', value: 'a' }]
        setLocations(data)
        setLocationId('a')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.appData.locationsResponse])

  useEffect(() => {
    if (
      props.appData &&
      props.appData.providersResponse &&
      props.appData.providersResponse.response
    ) {
      if (props.appData.providersResponse.response.responseCode === 0) {
        let providerData = props.appData.providersResponse.response.data
        if (providerData) {
          let data = providerData.map((item) => {
            return {
              name: item.middleName
                ? item.firstName + ' ' + item.middleName + ' ' + item.lastName
                : item.firstName + ' ' + item.lastName,
              value: item.id,
            }
          })
          // let provs = providerData.map((item) => {
          //   return {
          //     providerId: item.id,
          //     locationId: item.locationId,
          //   }
          // })
          data.unshift({ name: 'All providers', value: 'a' })
          setProviders(data)
          // setProvidersByLocation(provs)
        } else {
          let data = [{ name: 'All providers', value: 'a' }]
          setProviders(data)
          setProviderId('a')
        }
      } else if (
        props.appData.providersResponse.response.responseCode === 131
      ) {
        let data = [{ name: 'All providers', value: 'a' }]
        setProviders(data)
        // setProvidersByLocation([{ providerId: 'a', locationId: 'a' }])
        setProviderId('a')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.appData.providersResponse])

  useEffect(() => {
    // if (values.locationId !== 'a') {
    //   props.getProviders(values.locationId)
    // } else {
    //   props.getProviders(0)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.locationId])

  useEffect(() => {
    // if (providerId !== 'a') {
    //   let data = providersByLocation
    //     .filter((item) => item.providerId === providerId)
    //     .map((item) => item.locationId)
    //   if (data[0]) {
    //     // setValues({
    //     //   ...values,
    //     //   ...{
    //     //     locationId: data[0].locationId,
    //     //   },
    //     // })
    //     setLocationId(data[0].locationId)
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.providerId])

  const getLocationById = (id) => {
    let l = locations.find((item) => item.value === parseInt(id))
    if (l) {
      return l.name
    }
  }

  const getProviderById = (id) => {
    let p = providers.find((item) => item.value === parseInt(id))
    if (p) {
      return p.name
    }
  }

  const editDetails = () => {
    setCondition(true)
  }

  useEffect(() => {
    props.getTimeSlots({
      date: formatDate(calendarDate),
      providerId: providerId === 'a' ? 0 : providerId,
      locationId: locationId === 'a' ? 0 : locationId,
      sort: values.sort === 4 ? 0 : values.sort,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (values.sort) {
      props.getTimeSlots({
        date: formatDate(calendarDate),
        providerId: providerId === 'a' ? 0 : providerId,
        locationId: locationId === 'a' ? 0 : locationId,
        sort: values.sort === 4 ? 0 : values.sort,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.sort])

  useEffect(() => {
    if (
      props.appData &&
      props.appData.timeSlotsResponse &&
      props.appData.timeSlotsResponse.response
    ) {
      let data = props.appData.timeSlotsResponse.response.data.availibleTime
      if (data) {
        let dateList = data
          .filter((item) => item.slots[0])
          .map((item) => {
            return {
              weekday: item.dayInWeek,
              month: item.month,
              day: item.day,
              date: item.date,
            }
          })
        let timeList = data.filter((item) => item.slots[0]).map((item) => item)
        setDatesMobile(dateList.slice(0, 3))
        setDates(dateList)
        console.log(dateList.length)
        setTimeSlotsMobile(timeList.slice(0, 3))
        setTimeSlots(timeList)
        // setloading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.appData.timeSlotsResponse])

  useEffect(() => {
    if (providerId) {
      props.getTimeSlots({
        date: formatDate(calendarDate),
        providerId: providerId === 'a' ? 0 : providerId,
        locationId: locationId === 'a' ? 0 : locationId,
        sort: values.sort === 4 ? 0 : values.sort,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId, values.sort])

  useEffect(() => {
    if (locationId) {
      props.getTimeSlots({
        date: formatDate(calendarDate),
        providerId: providerId === 'a' ? 0 : providerId,
        locationId: locationId === 'a' ? 0 : locationId,
        sort: values.sort === 4 ? 0 : values.sort,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId, values.sort])

  const selectTime = (timeEntry, timeId, locId, provId) => {
    localStorage.setItem('selectedTime', timeEntry)
    localStorage.setItem('timeId', timeId)
    // setSelectedTime(timeEntry)
    setAppTime(localStorage.getItem('timeId'))
    // setValues({
    //   ...values,
    //   ...{
    //     locationId: locId,
    //     providerId: provId,
    //   },
    // })
    setLocationId(locId)
    setProviderId(provId)
  }

  const rightClick = () => {
    let currentDate = new Date(calendarDate)
    currentDate.setDate(currentDate.getDate() + 3)
    let selectedDate = currentDate
    props.getTimeSlots({
      date: formatDate(selectedDate),
      providerId: providerId === 'a' ? 0 : providerId,
      locationId: locationId === 'a' ? 0 : locationId,
      sort: values.sort === 4 ? 0 : values.sort,
    })
    setCalendarDate(selectedDate)
  }

  const leftClick = () => {
    let currentDate = new Date(calendarDate)
    currentDate.setDate(currentDate.getDate() - 3)
    let selectedDate = currentDate
    props.getTimeSlots({
      date: formatDate(selectedDate),
      providerId: providerId === 'a' ? 0 : providerId,
      locationId: locationId === 'a' ? 0 : locationId,
      sort: values.sort === 4 ? 0 : values.sort,
    })
    setCalendarDate(selectedDate)
  }

  const handleExpand = () => {
    setExpand(!expand)
  }

  useEffect(() => {
    setAppTime(localStorage.getItem('timeId'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appTime])

  function handledate(e) {
    setCalendarDate(new Date(e))
  }

  useEffect(() => {
    props.getTimeSlots({
      date: formatDate(calendarDate),
      providerId: providerId === 'a' ? 0 : providerId,
      locationId: locationId === 'a' ? 0 : locationId,
      sort: values.sort === 4 ? 0 : values.sort,
    })
    let currentDate = new Date(min)
    let lastDate = new Date()
    lastDate.setDate(lastDate.getDate() + 9)
    // let disabledDate = max
    if (calendarDate <= currentDate) {
      setDisableLeftClick(true)
    } else {
      setDisableLeftClick(false)
    }
    if (calendarDate >= lastDate) {
      setDisableRightClick(true)
    } else {
      setDisableRightClick(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarDate])

  const bookAppointment = () => {
    if (appTime) {
      console.log(appTime)
      localStorage.setItem('locationId', locationId)
      localStorage.setItem('providerId', providerId)
      window.location.href = '/patientDetails'
    }
  }

  return (
    <ThemeProvider theme={authTheme}>
      <div className={classes.root}>
        <div className={classes.containerWrapper}>
          <Grid container className={classes.container} spacing={2}>
            <Grid xs={12} sm={12} item className={classes.nameWrap}>
              <Typography className={classes.name}>
                {clinic ? clinic : 'Unknown Clinic'}
              </Typography>
            </Grid>
            <Grid xs={12} sm={6} item>
              {condition ? (
                <Dropdown
                  className={classes.dropdown}
                  placeholder="Select Provider"
                  options={providers}
                  onChange={handleProviderChange}
                  value={values.providerId}
                />
              ) : (
                <Grid container spacing={2} className={classes.label}>
                  <Grid item>
                    <Typography className={classes.labelName}>
                      {getProviderById(values.providerId)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <EditIcon
                      className={classes.editIcon}
                      onClick={editDetails}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid xs={12} sm={6} item>
              {condition ? (
                <Dropdown
                  className={classes.dropdown}
                  placeholder="Select Office location"
                  options={locations}
                  onChange={handleLocationChange}
                  value={values.locationId}
                />
              ) : (
                <Grid container spacing={2} className={classes.label}>
                  <Grid item>
                    <Typography className={classes.labelName}>
                      {getLocationById(values.locationId)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <EditIcon
                      className={classes.editIcon}
                      onClick={editDetails}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid xs={10} sm={6} item>
              <Dropdown
                className={classes.dropdown}
                placeholder="Select Time Slot"
                options={slots}
                {...useInput('sort', {
                  //   isRequired: {
                  //     value: true,
                  //     message: 'Select an option',
                  //   },
                })}
              />
            </Grid>
            <Grid xs={2} sm={6} item>
              <Datepicker
                required
                className={classes.dateField}
                value={calendarDate}
                onChange={handledate}
                // helperText={dobMessage}
                // error={validDob}
                minDate={min}
                maxDate={max}
              />
            </Grid>
          </Grid>

          <div className="dateSlider">
            <div className="dateSlider__arrowBtn arrowBtn--left">
              <div
                className={disableLeftClick && 'disabled__arrowBtn'}
                onClick={leftClick}
              >
                <ChevronLeftIcon />
              </div>
            </div>

            <div className="dateSlider__dateItems">
              {dates.length > 0 && width < breakpoint ? (
                datesMobile
                  .filter((item) => new Date(item.date) <= max)
                  .map((x, i) => {
                    return (
                      <div key={i} className="dateSlider__dateItem">
                        <div className="dateBox">
                          <Typography variant="body2">
                            {days[x.weekday]}
                          </Typography>
                          <Typography variant="subtitle2">
                            {month[x.month]} {x.day}
                          </Typography>
                        </div>
                      </div>
                    )
                  })
              ) : dates.length > 0 && width > breakpoint ? (
                dates
                  .filter((item) => new Date(item.date) <= max)
                  .map((x, i) => {
                    return (
                      <div key={i} className="dateSlider__dateItem">
                        <div className="dateBox">
                          <Typography variant="body2">
                            {days[x.weekday]}
                          </Typography>
                          <Typography variant="subtitle2">
                            {month[x.month]} {x.day}
                          </Typography>
                        </div>
                      </div>
                    )
                  })
              ) : dates.length === 0 ? (
                <div className={classes.dateContainer}>
                  <div className="noDates">
                    <Typography className="noDatesText" variant="body2">
                      No dates available
                    </Typography>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            <div className="dateSlider__arrowBtn arrowBtn--right">
              <div
                className={disableRightClick && 'disabled__arrowBtn'}
                onClick={rightClick}
              >
                <ChevronRightIcon />
              </div>
            </div>
          </div>

          <div className="timeSlots">
            <div className={!expand ? 'timeSlotsDefault' : 'timeSlotsExpanded'}>
              {timeSlots && width < breakpoint
                ? timeSlotsMobile.map((item, i) => {
                    let times = item.slots
                    return (
                      <div key={i} className="timeslot">
                        {times ? (
                          times.map((x) => {
                            return (
                              <div
                                key={x.id}
                                onClick={() =>
                                  selectTime(
                                    x.startTime,
                                    x.id,
                                    x.locationId,
                                    x.providerId,
                                  )
                                }
                                className={
                                  x.id === parseInt(appTime)
                                    ? 'timeSlot--active'
                                    : 'timeSlot--default'
                                }
                              >
                                {TimestamptoTime(x.startTime)}
                              </div>
                            )
                          })
                        ) : (
                          <div className="timeSlot--default">No data</div>
                        )}
                      </div>
                    )
                  })
                : timeSlots.map((item, i) => {
                    let times = item.slots
                    return (
                      <div key={i} className="timeslot">
                        {times.map((x) => {
                          return (
                            <div
                              key={x.id}
                              onClick={() =>
                                selectTime(
                                  x.startTime,
                                  x.id,
                                  x.locationId,
                                  x.providerId,
                                )
                              }
                              className={
                                x.id === parseInt(appTime)
                                  ? 'timeSlot--active'
                                  : 'timeSlot--default'
                              }
                            >
                              {x !== []
                                ? TimestamptoTime(x.startTime)
                                : 'No data'}
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
            </div>

            <div className="expandBtn">
              {expand ? (
                <Button onClick={handleExpand}>
                  <ExpandLessIcon />
                </Button>
              ) : (
                <Button onClick={handleExpand}>
                  <ExpandMoreIcon />
                </Button>
              )}
            </div>
          </div>

          <Grid container className={classes.Btncontainer}>
            <Grid xs={12} sm={12} item>
              <Button
                className={classes.confirmBtn}
                variant="contained"
                color="primary"
                size="large"
                onClick={bookAppointment}
              >
                Book Appointment
              </Button>
            </Grid>
          </Grid>
          <div className={classes.copyright}>
            <Typography
              variant="caption"
              align="center"
              color="textSecondary"
              display="block"
            >
              Copyright &copy; {new Date().getFullYear()}-
              {new Date().getFullYear() + 1} trillium.health
            </Typography>
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

const mapStateToProps = (state) => ({
  appData: state.appointment,
})

const mapDispatchToProps = (dispatch) => ({
  getLocations: (values) => dispatch(getLocations(values)),
  getProviders: (values) => dispatch(getProviders(values)),
  getTimeSlots: (values) => dispatch(getTimeSlots(values)),
})
Appointment.propTypes = {
  getLocations: PropTypes.func,
  getProviders: PropTypes.func,
  getTimeSlots: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Appointment)
