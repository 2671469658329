import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  getAppointmentDetails,
  getLocations,
  getProviders,
} from 'Redux/Appointment/appointment.actions'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles, createTheme } from '@material-ui/core/styles'
// import trilliumHealthLogo from '../../assets/images/trillium-health-logo.png'
import { ThemeProvider } from '@material-ui/styles'
import Loader from 'BaseComponents/Loader'
import './style.css'

const authTheme = createTheme({
  palette: {
    primary: {
      main: '#088B8C',
    },
    secondary: {
      main: '#5F6064',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
})

const useStyles = makeStyles((theme) => ({
  root: {
    '@media(min-width: 760px)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  containerWrapper: {
    display: 'block',
    height: 'fit-content',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '2rem',

    '@media(min-width: 760px)': {
      paddingTop: '6rem',
      maxWidth: '500px',
    },
  },
  name: {
    display: 'flex',
    justifyContent: 'start',
    textAlign: 'start',
    margin: '4rem 0 1rem',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  subHeading: {
    margin: '0 0 1rem',
    fontSize: '24px',
    fontWeight: '400',
    color: '#4F566B',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  clinicName: {
    fontWeight: '600',
    color: '#2A2F45',
  },
  //   dob: {
  //     width: '100% !important',
  //   },
  boxWrap: {
    paddingTop: '0 !important',
    marginBottom: '16px',
  },
  boxHeading: {
    color: '#2A2F45',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  editIcon: {
    color: '#8792A2',
    fontSize: '18px',
  },
  detailsBox: {
    height: 'fit-content',
    borderRadius: '6px',
    backgroundColor: '#E0F0F2',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  detailsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '2rem',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  doctorName: {
    color: '#1A1F36',
    fontSize: '18px',
    fontWeight: '600',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
    '@media(max-width: 500px)': {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  address: {
    color: '#3C4257',
    fontSize: '14px',
    fontWeight: '500',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
    '@media(max-width: 500px)': {
      fontSize: '12px',
    },
  },
  speciality: {
    color: '#697386',
    fontSize: '15px',
    fontWeight: '500',
    margin: '5px 0 1px',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
    '@media(max-width: 500px)': {
      fontSize: '13px',
    },
  },
  date: {
    color: '#1A1F36',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '22px',
    marginTop: '18px',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
    '@media(max-width: 500px)': {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  terms: {
    color: '#4F566B',
    fontSize: '12px',
    fontWeight: '400',
  },
  successMsg: {
    margin: '0 0 1rem',
    color: '#4F566B',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '19px',
    textAlign: 'justify',
    '@media(min-width: 760px)': {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  copyright: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
}))

// const months = [
//   'Jan',
//   'Feb',
//   'Mar',
//   'Apr',
//   'May',
//   'Jun',
//   'Jul',
//   'Aug',
//   'Sep',
//   'Oct',
//   'Nov',
//   'Dec',
// ]

// const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']

function Success(props) {
  const classes = useStyles()
  // const [details, setDetails] = useState({})
  const [address, setAddress] = useState({})
  const [provider, setProvider] = useState({})
  const [clinic, setClinic] = useState(localStorage.getItem('clinicName'))
  const formatTime = localStorage.getItem('formatTime')
  const [loading, setloading] = useState(true)

  useEffect(() => {
    props.getAppointmentDetails(localStorage.getItem('bookingId'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      props.AppData &&
      props.AppData.appointmentDetails &&
      props.AppData.appointmentDetails.response
    ) {
      // let data = props.AppData.appointmentDetails.response.data
      // setDetails(data)
      setClinic(clinic)
      setloading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppData.appointmentDetails])

  useEffect(() => {
    props.getLocations(0)
    props.getProviders(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      props.AppData &&
      props.AppData.locationsResponse &&
      props.AppData.locationsResponse.response
    ) {
      let id = localStorage.getItem('locationId')
      let data = props.AppData.locationsResponse.response.data
      let x = data.filter((item) => item.id === parseInt(id))
      setAddress(x[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppData.locationsResponse])

  useEffect(() => {
    if (
      props.AppData &&
      props.AppData.providersResponse &&
      props.AppData.providersResponse.response
    ) {
      let id = localStorage.getItem('providerId')
      let data = props.AppData.providersResponse.response.data
      let x = data.filter((item) => item.id === parseInt(id))
      setProvider(x[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppData.providersResponse])

  // useEffect(() => {
  //   localStorage.setItem('success', true)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])

  let render = loading ? (
    <Loader />
  ) : (
    <ThemeProvider theme={authTheme}>
      <div className={classes.root}>
        {/* <header className={classes.header}>
          <div className={classes.header__bar}>
            <a href="https://tensaw.health/">
              <img
                src={trilliumHealthLogo}
                alt="trillium-health-logo"
                className={classes.logo}
              />
            </a>
          </div>
        </header> */}
        <div className={classes.containerWrapper}>
          <Grid container className={classes.container} spacing={2}>
            <Grid xs={12} sm={12} item>
              <p className={classes.subHeading}>
                Your appointment at{' '}
                <span className={classes.clinicName}>
                  {/* {details ? details.clinicName : 'clinic'} */}
                  {clinic}
                </span>{' '}
                has been scheduled
              </p>
            </Grid>

            <Grid item xs={12} sm={12} className={classes.boxHeading}>
              <div>Appointment details</div>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.boxWrap}>
              <div className={classes.detailsBox}>
                <Grid container className={classes.detailsContainer}>
                  <Grid item xs={12} sm={12} className={classes.doctorName}>
                    Dr. {provider.firstName + ' ' + provider.lastName}
                  </Grid>
                  <Grid item xs={12} sm={12} className={classes.speciality}>
                    {provider.speciality}
                  </Grid>
                  <Grid item xs={12} sm={12} className={classes.address}>
                    {address.locationName} <br />
                    {address.city}, {address.state} {address.zip}
                    <br />
                    {provider.phone}
                  </Grid>
                  <Grid item xs={12} sm={12} className={classes.date}>
                    {formatTime}
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid xs={12} sm={12} item>
              <div className={classes.successMsg}>
                Your appointment has been scheduled. We will let you know when
                the office confirms this visit.
              </div>
            </Grid>
          </Grid>
          <div className={classes.copyright}>
            <Typography
              variant="caption"
              align="center"
              color="textSecondary"
              display="block"
            >
              Copyright &copy; {new Date().getFullYear()}-
              {new Date().getFullYear() + 1} trillium.health
            </Typography>
          </div>
        </div>
      </div>
    </ThemeProvider>
  )

  return render
}

const mapStateToProps = (state) => ({
  AppData: state.appointment,
})

const mapDispatchToProps = (dispatch) => ({
  getLocations: (values) => dispatch(getLocations(values)),
  getAppointmentDetails: (values) => dispatch(getAppointmentDetails(values)),
  getProviders: (values) => dispatch(getProviders(values)),
})

Success.propTypes = {
  getLocations: PropTypes.func,
  getAppointmentDetails: PropTypes.func,
  getProviders: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(Success)
