export const Apis = {
  Login_login: 'trillium-af-patient-service/v1/authenticate/login',
  Login_verifyPin: 'trillium-af-patient-service/v1/authenticate',

  Appointment_getLocations: 'trillium-af-patient-service/v1/location/list/all',
  Appointment_getProviders: 'trillium-af-patient-service/v1/provider/list/all',
  Appointment_getTimeSlots:
    'trillium-af-patient-service/v1/appointment/search/slots',
  Appointment_getPatientDetails: 'trillium-af-patient-service/v1/patient/',
  Appointment_confirm: 'trillium-af-patient-service/v1/appointment/',
  Appointment_getAppointmentDetails: 'trillium-af-patient-service/v1/appointment',
}
