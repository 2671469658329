import {
  GET_LOCATIONS_RESPONSE,
  GET_PROVIDERS_RESPONSE,
  GET_TIME_SLOTS_RESPONSE,
  GET_PATIENT_DETAILS_RESPONSE,
  CONFIRM_RESPONSE,
  GET_APPOINTMENT_DETAILS_RESPONSE,
} from './appointment.types'


const INITIAL_STATE = {
  locationsResponse: {},
  providersResponse: {},
  timeSlotsResponse: {},
  patientDetailsResponse: {},
  confirmResponse: {},
  appointmentDetails: {},
}

const appointmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOCATIONS_RESPONSE: {
      return { ...state, ...{ locationsResponse: action.payload } }
    }
    case GET_PROVIDERS_RESPONSE: {
      return { ...state, ...{ providersResponse: action.payload } }
    }
    case GET_TIME_SLOTS_RESPONSE: {
      return { ...state, ...{ timeSlotsResponse: action.payload } }
    }
    case GET_PATIENT_DETAILS_RESPONSE: {
      return { ...state, ...{ patientDetailsResponse: action.payload } }
    }
    case CONFIRM_RESPONSE: {
      return { ...state, ...{ confirmResponse: action.payload } }
    }
    case GET_APPOINTMENT_DETAILS_RESPONSE: {
      return { ...state, ...{ appointmentDetails: action.payload } }
    }
    default:
      return state
  }
}

export default appointmentReducer