import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import toast from 'react-hot-toast'
import {
  getPatientDetails,
  confirm,
  getLocations,
  getProviders,
} from 'Redux/Appointment/appointment.actions'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import PhoneFormat from 'BaseComponents/PhoneFormat'
import { makeStyles, createTheme, withStyles } from '@material-ui/core/styles'
// import trilliumHealthLogo from '../../assets/images/trillium-health-logo.png'
import { ThemeProvider } from '@material-ui/styles'
import Input from 'BaseComponents/Input'
import { useForm } from 'Hooks/validator'
import Dropdown from 'BaseComponents/Dropdown'
// import Loader from 'BaseComponents/Loader'
import axios from 'axios'
import './style.css'

const authTheme = createTheme({
  palette: {
    primary: {
      main: '#088B8C',
    },
    secondary: {
      main: '#5F6064',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
  },
})

const useStyles = makeStyles((theme) => ({
  root: {
    '@media(min-width: 760px)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  containerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    height: 'fit-content',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '2rem',
    '@media(min-width: 760px)': {
      paddingTop: '6rem',
      maxWidth: '850px',
    },
  },
  name: {
    display: 'flex',
    justifyContent: 'start',
    textAlign: 'start',
    margin: '4rem 0 1rem',
  },
  subHeading: {
    margin: '0 0 1rem',
    fontSize: '24px',
    fontWeight: '400',
    color: '#4F566B',
  },
  clinicName: {
    fontWeight: '600',
    color: '#2A2F45',
  },
  patient: {
    paddingBottom: '1px !important',
  },
  boxWrap: {
    paddingTop: '0 !important',
    marginBottom: '16px',
  },
  boxHeading: {
    color: '#2A2F45',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  editIcon: {
    color: '#8792A2',
    fontSize: '18px',
  },
  detailsBox: {
    height: 'fit-content',
    borderRadius: '6px',
    backgroundColor: '#E0F0F2',
  },
  detailsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '2rem',
  },
  doctorName: {
    color: '#1A1F36',
    fontSize: '18px',
    fontWeight: '600',
    '@media(max-width: 500px)': {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  address: {
    color: '#3C4257',
    fontSize: '14px',
    fontWeight: '500',
    '@media(max-width: 500px)': {
      fontSize: '12px',
    },
  },
  speciality: {
    color: '#697386',
    fontSize: '15px',
    fontWeight: '500',
    margin: '5px 0 1px',
    '@media(max-width: 500px)': {
      fontSize: '13px',
    },
  },
  date: {
    color: '#1A1F36',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '22px',
    marginTop: '18px',
    '@media(max-width: 500px)': {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  terms: {
    margin: '0.2rem 0 0.1rem',
  },
  checkBox: {
    display: 'flex',
    alignItems: 'flex-start',
    marginRight: '0',
    '& span': {
      paddingTop: '0 !important',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: '400',
    },
  },
  confirmBtn: {
    margin: '0 0 1rem',
    width: '100%',
  },
  copyright: {
    padding: '2rem 0 1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
}))

const GreenCheckbox = withStyles({
  root: {
    color: '#697386',
    '&$checked': {
      color: '#139696',
    },
  },
})((props) => <Checkbox color="default" {...props} />)

const methods = [
  { name: 'SMS', value: 1 },
  { name: 'Email', value: 2 },
  { name: 'Voice call', value: 3 },
]

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']

function PatientDetails(props) {
  const classes = useStyles()
  const history = useHistory()
  const [details, setDetails] = useState({})
  const [address, setAddress] = useState({})
  const [provider, setProvider] = useState({})
  const [showTime, setShowTime] = useState('')
  const [isPhoneValid, setIsPhoneValid] = useState(true)
  const [invalid, setInvalid] = useState(false)
  const [message, setMessage] = useState('')
  const [Ipaddress, setIpaddress] = useState('')
  const [loading, setloading] = useState(true)
  const [display, setDisplay] = useState('none')
  const [check, setCheck] = React.useState(true)
  let clinic = localStorage.getItem('clinicName')
  let time = localStorage.getItem('selectedTime')

  const phoneFormat = () => {
    if (/^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/.test(values.phone) === false) {
      setIsPhoneValid(true)
    }
  }

  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIpaddress(res.data.IPv4)
  }

  useEffect(() => {
    getData()
  }, [])

  const defaultValues = {
    id: 0,
    clinicId: 0,
    phone: '',
    email: '',
    reason: '',
    notes: '',
    contactMethod: 1,
    consent: check,
  }

  const { useInput, isValid, values, setValues } = useForm(defaultValues)

  useEffect(() => {
    props.getPatientDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      props.AppData &&
      props.AppData.patientDetailsResponse &&
      props.AppData.patientDetailsResponse.response
    ) {
      let data = props.AppData.patientDetailsResponse.response.data
      setDetails(data)
      setValues({
        id: data.id,
        clinicId: data.clinicId,
        phone: data.phone,
        email: data.email,
        reason: '',
        notes: '',
        contactMethod: 1,
        consent: check,
      })
      setloading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppData.patientDetailsResponse])

  const editDetails = () => {
    window.location.href = '/appointment'
  }

  useEffect(() => {
    props.getLocations(0)
    props.getProviders(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      props.AppData &&
      props.AppData.locationsResponse &&
      props.AppData.locationsResponse.response
    ) {
      let id = localStorage.getItem('locationId')
      let data = props.AppData.locationsResponse.response.data
      let x = data
        .filter((item) => item.id === parseInt(id))
        .map((item) => item)
      setAddress(x[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppData.locationsResponse])

  useEffect(() => {
    if (
      props.AppData &&
      props.AppData.providersResponse &&
      props.AppData.providersResponse.response
    ) {
      let id = localStorage.getItem('providerId')
      let data = props.AppData.providersResponse.response.data
      let x = data
        .filter((item) => item.id === parseInt(id))
        .map((item) => item)
      setProvider(x[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppData.providersResponse])

  const onCheck = (event) => {
    setCheck(event.target.checked)
    setValues({
      ...values,
      ...{
        consent: event.target.checked ? 1 : 0,
      },
    })
  }

  const confirmDetails = () => {
    // setloading(true)
    if (isValid && values.reason !== '') {
      props.confirm({
        id: 0,
        clinicId: values.clinicId,
        patientId: values.id,
        providerId: localStorage.getItem('providerId'),
        patientPhoneNumber: values.phone,
        patientEmail: values.email,
        locationId: localStorage.getItem('locationId'),
        startTime: localStorage.getItem('selectedTime'),
        endTime: 0,
        mode: 1,
        notes: values.notes,
        reason: values.reason,
        status: 1,
        type: 1,
        consent: localStorage.getItem('getConsent') === 'true' ? false : check,
        ipAddress: Ipaddress,
        contactMethod: values.contactMethod,
        slotId: localStorage.getItem('timeId'),
        previousAppointmentId:
          localStorage.getItem('appointmentId') === 'a'
            ? null
            : parseInt(localStorage.getItem('appointmentId')),
      })
    } else {
      toast.error('Something went wrong')
      let errorMessage = 'Enter a valid reason'
      setInvalid(true)
      setMessage(errorMessage)
    }
  }

  useEffect(() => {
    if (
      props.AppData &&
      props.AppData.confirmResponse &&
      props.AppData.confirmResponse.response
    ) {
      if (props.AppData.confirmResponse.response.responseCode === 0) {
        let id = props.AppData.confirmResponse.response.data.id
        localStorage.setItem('bookingId', id)
        history.push('/success')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppData.confirmResponse])

  const TimestamptoTime = (timestamp) => {
    const time = new Date(timestamp).toLocaleTimeString('en-US')
    let hours = new Date(timestamp).getHours()
    let ampm = hours >= 12 ? 'PM' : 'AM'
    const split1 = time.split(':')
    // const split2 = split1[2].split('')
    const newTime = `${split1[0]}:${split1[1]} ${ampm}`
    const date = new Date(timestamp)
    const weekday = days[date.getDay()]
    const month = months[date.getMonth()]
    const day = date.getDate()
    const formatDate = `${weekday}, ${month} ${day} at ${newTime}`
    setShowTime(formatDate)
    localStorage.setItem('formatTime', formatDate)
  }

  useEffect(() => {
    TimestamptoTime(time)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   if(localStorage.getItem('success') === 'true') {
  //     window.location.href = '/login'
  //   }
  // },[])

  useEffect(() => {
    if (loading) {
      setDisplay('none')
    } else {
      setDisplay('block')
    }
  }, [loading])

  return (
    <ThemeProvider theme={authTheme}>
      <div style={{ display: display }} className={classes.root}>
        <div className={classes.containerWrapper}>
          <Grid container className={classes.container} spacing={6}>
            <Grid item xs={12} sm={6} className={classes.patient}>
              <Grid container spacing={2}>
                <Grid xs={12} sm={12} item>
                  <p className={classes.subHeading}>
                    Please verify your appointment details with{' '}
                    <span className={classes.clinicName}>
                      {clinic ? clinic : 'Unknown Clinic'}
                    </span>
                  </p>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Input
                    InputProps={{ disableUnderline: true, readOnly: true }}
                    type="text"
                    label="Patient name"
                    value={
                      details.firstName +
                      ' ' +
                      details.middleName +
                      ' ' +
                      details.lastName
                    }
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Input
                    label="Email"
                    type="email"
                    {...useInput('email', {
                      isLength: {
                        value: { max: 50 },
                        message: 'Maximum length is 50',
                      },
                      matches: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Enter a valid email',
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <PhoneFormat
                    required={isPhoneValid}
                    id={'phone'}
                    type="text"
                    label="Phone"
                    onKeyUp={phoneFormat}
                    {...useInput('phone', {
                      isRequired: {
                        value: true,
                        message: 'Enter a valid phone number',
                      },
                      matches: {
                        value: /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
                        message: 'Enter a valid phone number',
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Input
                    label="Reason for visit"
                    type="text"
                    required
                    helperText={message}
                    error={invalid}
                    {...useInput('reason', {
                      isRequired: {
                        value: true,
                        message: 'Enter a valid reason',
                      },
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Dropdown
                    className={classes.dropdown}
                    label="Preferred contact method"
                    options={methods}
                    {...useInput('contactMethod', {
                      //   isRequired: {
                      //     value: true,
                      //     message: 'Select an option',
                      //   },
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.provider}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} className={classes.boxHeading}>
                  <div>Review details</div>
                  <div>
                    <EditIcon
                      className={classes.editIcon}
                      onClick={editDetails}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.boxWrap}>
                  <div className={classes.detailsBox}>
                    <Grid container className={classes.detailsContainer}>
                      <Grid item xs={12} sm={12} className={classes.doctorName}>
                        Dr. {provider.firstName + ' ' + provider.lastName}
                      </Grid>
                      <Grid item xs={12} sm={12} className={classes.speciality}>
                        {provider.speciality}
                      </Grid>
                      <Grid item xs={12} sm={12} className={classes.address}>
                        {address.locationName} <br />
                        {address.city}, {address.state} {address.zip}
                        <br />
                        {provider.phone}
                      </Grid>
                      <Grid item xs={12} sm={12} className={classes.date}>
                        {showTime}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid xs={12} sm={12} item className={classes.terms}>
                  {localStorage.getItem('getConsent') === 'true' ? null : (
                    <FormControlLabel
                      className={classes.checkBox}
                      control={
                        <GreenCheckbox
                          className={classes.checkBox}
                          checked={check}
                          color="primary"
                          onChange={onCheck}
                        />
                      }
                      label="I consent to receiving further text messages from this clinic regarding appointments, messages and balance"
                    />
                  )}
                </Grid>
                <Grid xs={12} sm={12} item>
                  <Button
                    className={classes.confirmBtn}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={confirmDetails}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.copyright}>
          <Typography
            variant="caption"
            align="center"
            color="textSecondary"
            display="block"
          >
            Copyright &copy; {new Date().getFullYear()}-
            {new Date().getFullYear() + 1} trillium.health
          </Typography>
        </div>
      </div>
    </ThemeProvider>
  )
}

const mapStateToProps = (state) => ({
  AppData: state.appointment,
})

const mapDispatchToProps = (dispatch) => ({
  getPatientDetails: (values) => dispatch(getPatientDetails(values)),
  confirm: (values) => dispatch(confirm(values)),
  getLocations: (values) => dispatch(getLocations(values)),
  getProviders: (values) => dispatch(getProviders(values)),
})

PatientDetails.propTypes = {
  getPatientDetails: PropTypes.func,
  confirm: PropTypes.func,
  getLocations: PropTypes.func,
  getProviders: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails)
