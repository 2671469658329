export const GET_LOCATIONS = 'GET_LOCATIONS'
export const GET_LOCATIONS_RESPONSE = 'GET_LOCATIONS_RESPONSE'
export const GET_PROVIDERS = 'GET_PROVIDERS'
export const GET_PROVIDERS_RESPONSE = 'GET_PROVIDERS_RESPONSE'
export const GET_TIME_SLOTS = 'GET_TIME_SLOTS'
export const GET_TIME_SLOTS_RESPONSE = 'GET_TIME_SLOTS_RESPONSE'
export const GET_PATIENT_DETAILS = 'GET_PATIENT_DETAILS'
export const GET_PATIENT_DETAILS_RESPONSE = 'GET_PATIENT_DETAILS_RESPONSE'
export const CONFIRM = 'CONFIRM'
export const CONFIRM_RESPONSE = 'CONFIRM_RESPONSE'
export const GET_APPOINTMENT_DETAILS = 'GET_APPOINTMENT_DETAILS'
export const GET_APPOINTMENT_DETAILS_RESPONSE = 'GET_APPOINTMENT_DETAILS_RESPONSE'
export const REQUEST_CANCEL = 'REQUEST_CANCEL'