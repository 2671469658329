import React from 'react'
import Login from 'Containers/Login'
import Authenticate from 'Containers/Authenticate'
import Appointment from 'Containers/Appointment'
import PatientDetails from 'Containers/PatientDetails'
import Success from 'Containers/Success'
import Loader from './BaseComponents/Loader'
import { PrivateRoute } from 'Utils/private-route'
import { BrowserRouter, Switch, Redirect } from 'react-router-dom'

const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Switch>
            <PrivateRoute
              authed={true}
              path="/appointment"
              component={Appointment}
            />
            <PrivateRoute path="/patientDetails" component={PatientDetails} />
            <PrivateRoute path="/success" component={Success} />
            <PrivateRoute path="/login" component={Login} />
            <PrivateRoute path="/" component={Authenticate} />
            <Redirect from="/" to="/login" />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </div>
  )
}

export default App
