import {
  LOGIN,
  LOGIN_RESPONSE,
  VERIFY_PIN,
  VERIFY_PIN_RESPONSE,
  GET_IP,
  GET_IP_RESPONSE,
} from './login.types'

export const clickLogin = (logData) => ({
  type: LOGIN,
  payload: logData,
})

export const loginResponse = (user) => ({
  type: LOGIN_RESPONSE,
  payload: user,
})

// export const logoutApi = (token) => ({
//   type: LOGOUT,
//   payload: token,
// })

// export const logoutApiResponse = (token) => ({
//   type: LOGOUT_RES,
//   payload: token,
// })

export const checkPin = (pinData) => ({
  type: VERIFY_PIN,
  payload: pinData,
})

export const checkPinResponse = (pinResponse) => ({
  type: VERIFY_PIN_RESPONSE,
  payload: pinResponse,
})

export const getIp = (data) => ({
  type: GET_IP,
  payload: data,
})

export const getIpResponse = (response) => ({
  type: GET_IP_RESPONSE,
  payload: response,
})
