import React from 'react'
import { makeStyles, alpha, styled } from '@material-ui/core/styles'
import { FormControl } from '@material-ui/core'

import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  date: {
    margin: '0',
    // width: '19.5rem',
    minWidth: '200px',
    minHeight: '30px',
    color: '#2A2F45',
    fontWeight: '500',
    height: '36px',
  },
}))

const DateField = styled(KeyboardDatePicker)({
  '& .PickerWithState': {
    '&: Mui-focused': {
      border: 'none',
    },
  },

  'label + &': {
    marginTop: '1.3rem',
  },

  '& .Mui-focused': {
    border: 'none',
    boxShadow: `${alpha('#F2F2F2', 0.2)} 0.3px -1.5px 0px 0.1rem`,
    borderColor: '#F2F2F2',
    borderBottom: '0',
    outline: 'none',
    transition:
      'border-color 300ms cubic-bezier(0.4, 0, 0.2, 0.1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 0.1) 0ms',
  },
  '& fieldset': {
    top: '-5px',
  },
  '#date-of-birth': {
      borderBottom: '1px solid red !important',
  }
})

export default function Datepicker(props) {
  const classes = useStyles()

  return (
    <FormControl className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateField
          tabIndex={-1}
          error={props.error}
          label={props.label}
          className={classes.date}
          variant="dialog"
          margin="normal"
          id="date-of-birth"
          format="MM/dd/yyyy"
          placeholder="mm/dd/yyyy"
          KeyboardButtonProps={{
            'aria-label': 'change date',
            tabIndex: '-1',
          }}
          FormHelperTextProps={{
            className: 'date-helper-text',
          }}
          {...props}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  )
}
