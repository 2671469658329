import { combineEpics } from 'redux-observable'
import loginEpic from './Login/login.epic'
import appointmentEpic from './Appointment/appointment.epic'

const rootEpic = combineEpics(
  loginEpic.login,
  loginEpic.verifyPin,
  loginEpic.getIpApi,
  appointmentEpic.getLocations,
  appointmentEpic.getProviders,
  appointmentEpic.getTimeSlots,
  appointmentEpic.getPatientDetails,
  appointmentEpic.confirm,
  appointmentEpic.getAppointmentDetails,
)
export default rootEpic
