import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
// import { checkPropTypes } from 'prop-types'

const useStyles = makeStyles(() => ({
  button: {
    background: '#088B8C',
    border: 0,
    fontSize: 16,
    borderRadius: 4,
    color: 'white',
    height: 48,
    cursor: 'pointer',
    fontWeight: 'bold',
    width: 'auto',
    minWidth: '310px',
    minHeight: '30px',
    padding: '10px 13px',
    transition: '0.3s ease all',

    '&:hover': {
      background: '#10A8A9',
    },
  },
}))

export default function LoginBtn(props) {
  const classes = useStyles()

  return (
    <div>
      <Button {...props} className={classes.button} type="submit">
        Login
      </Button>
    </div>
  )
}
