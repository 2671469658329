import { combineReducers } from 'redux'
import loginReducer from './Login/login.reducer'
import appointmentReducer from './Appointment/appointment.reducer'

const rootReducer = combineReducers({
  login: loginReducer,
  appointment: appointmentReducer,
})

export default rootReducer
