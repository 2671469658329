import { ofType } from 'redux-observable'
import { switchMap, map, takeUntil } from 'rxjs/operators'
import { ajax } from 'Utils'
import {
  GET_LOCATIONS,
  GET_PROVIDERS,
  GET_TIME_SLOTS,
  GET_PATIENT_DETAILS,
  CONFIRM,
  GET_APPOINTMENT_DETAILS,
  REQUEST_CANCEL,
} from './appointment.types'

import {
  getLocationsResponse,
  getProvidersResponse,
  getTimeSlotsResponse,
  getPatientDetailsResponse,
  confirmResponse,
  getAppointmentDetailsResponse,
} from './appointment.actions'
import environment from 'environments/environment'
import { Apis } from 'Redux/APIs'

const baseUrl = environment.baseUrl

const appointmentEpic = {}

appointmentEpic.getLocations = (action$) =>
  action$.pipe(
    ofType(GET_LOCATIONS),
    switchMap((action) =>
      ajax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/${Apis.Appointment_getLocations}?locationId=${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getLocationsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

appointmentEpic.getProviders = (action$) =>
  action$.pipe(
    ofType(GET_PROVIDERS),
    switchMap((action) =>
      ajax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/${Apis.Appointment_getProviders}?locationId=${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getProvidersResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

appointmentEpic.getTimeSlots = (action$) =>
  action$.pipe(
    ofType(GET_TIME_SLOTS),
    switchMap((action) =>
      ajax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/${Apis.Appointment_getTimeSlots}?date=${action.payload.date}&providerId=${action.payload.providerId}&locationId=${action.payload.locationId}&sort=${action.payload.sort}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getTimeSlotsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

appointmentEpic.getPatientDetails = (action$) =>
  action$.pipe(
    ofType(GET_PATIENT_DETAILS),
    switchMap((action) =>
      ajax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/${Apis.Appointment_getPatientDetails}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getPatientDetailsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

appointmentEpic.confirm = (action$) =>
  action$.pipe(
    ofType(CONFIRM),
    switchMap((action) =>
      ajax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/${Apis.Appointment_confirm}`,
        method: 'POST',
        body: action.payload,
      }).pipe(
        map(
          (response) => confirmResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

appointmentEpic.getAppointmentDetails = (action$) =>
  action$.pipe(
    ofType(GET_APPOINTMENT_DETAILS),
    switchMap((action) =>
      ajax({
        headers: { 'Content-Type': 'application/json' },
        url: `${baseUrl}/${Apis.Appointment_getAppointmentDetails}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getAppointmentDetailsResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        ),
      ),
    ),
  )

export default appointmentEpic
