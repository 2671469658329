export const ErrorHandler = (code, message) => {
  switch (code) {
    case 0:
      return {
        message: null,
        errorStatus: null,
        errorCode: code,
      }
    case 501:
      return {
        message: message.fraudulent,
        errorCode: code,
      }
    case 502:
      return {
        message: message.incorrect_zip,
        errorCode: code,
      }
    case 503:
      return {
        message: message.generic_decline,
        errorCode: code,
      }
    case 504:
      return {
        message: message.insufficient_funds,
        errorCode: code,
      }
    case 505:
      return {
        message: message.lost_card,
        errorCode: code,
      }
    case 506:
      return {
        message: message.stolen_card,
        errorCode: code,
      }
    case 507:
      return {
        message: message.expired_card,
        errorCode: code,
      }
    case 508:
      return {
        message: message.incorrect_cvc,
        errorCode: code,
      }
    case 509:
      return {
        message: message.processing_error,
        errorCode: code,
      }
    case 510:
      return {
        message: message.incorrect_number,
        errorCode: code,
      }
    case 511:
      return {
        message: message.stripe_incomplete_payment,
        errorCode: code,
      }
    case 512:
      return {
        message: message.payment_intent_unexpected_state,
        errorCode: code,
      }
    case 513:
      return {
        message: message.currency,
        errorCode: code,
      }
    case 514:
      return {
        message: message.stripe_key_found,
        errorCode: code,
      }
    case 515:
      return {
        message: message.no_customer_found,
        errorCode: code,
      }
    case 516:
      return {
        message: message.stripe_id_not_found,
        errorCode: code,
      }
    case 517:
      return {
        message: message.resource_missing,
        errorCode: code,
        param: message.param,
      }
    case 101:
      return {
        message: message.database_save,
        errorCode: code,
      }
    case 102:
      return {
        message: message.no_matching_record,
        errorCode: code,
      }
    case 103:
      return {
        message: message.database_query,
        errorCode: code,
      }
    case 104:
      return {
        message: message.database_update,
        errorCode: code,
      }
    case 105:
      return {
        message: message.database_delete,
        errorCode: code,
      }
    case 106:
      return {
        message: message.invalid_user,
        errorCode: code,
      }
    case 107:
      return {
        message: message.null_argument,
        errorCode: code,
      }
    case 108:
      return {
        message: message.general_database,
        errorCode: code,
      }
    case 109:
      return {
        message: message.invalid_argument,
        errorCode: code,
      }
    case 110:
      return {
        message: message.authentication,
        errorCode: code,
      }
    case 111:
      return {
        message: message.accessToken,
        errorCode: code,
      }
    case 112:
      return {
        message: message.clinic_not_found,
        errorCode: code,
      }
    case 113:
      return {
        message: message.parameter_invalid_empty,
        errorCode: code,
        param: message.param,
      }
    case 114:
      return {
        message: message.parameter_missing,
        errorCode: code,
        param: message.param,
      }
    case 115:
      return {
        message: message.parameter_invalid_integer,
        errorCode: code,
        param: message.param,
      }
    case 116:
      return {
        message: message.cannot_connect_to_database,
        errorCode: code,
      }
    case 117:
      return {
        message: message.database_retrieval,
        errorCode: code,
      }
    case 118:
      return {
        message: message.application,
        errorCode: code,
      }
    case 119:
      return {
        message: message.database_locking_failure,
        errorCode: code,
      }
    case 120:
      return {
        message: message.integrity_constraint,
        errorCode: code,
      }
    case 121:
      return {
        message: message.data_type_mismatch,
        errorCode: code,
      }
    case 122:
      return {
        message: message.logout_failed,
        errorCode: code,
      }
    case 123:
      return {
        message: message.token_expired,
        errorCode: code,
      }
    case 124:
      return {
        message: message.invalid_token,
        errorCode: code,
      }
    case 125:
      return {
        message: message.unauthorized,
        errorCode: code,
      }
    case 126:
      return {
        message: message.session_validation,
        errorCode: code,
      }
    case 127:
      return {
        message: message.session_not_found,
        errorCode: code,
      }
    case 128:
      return {
        message: message.patient_not_found,
        errorCode: code,
      }
    case 129:
      return {
        message: message.patient_blocked,
        errorCode: code,
      }
    case 130:
      return {
        message: message.login_failed,
        errorCode: code,
      }
    default:
      return null
  }
}
